<template>
  <div class="wrapper wrapper--team">
    <div class="wrapper-tour wrapper-tour--team" ref="content" @click="contentElementClicked($event)">
      <iframe :src="frameUrl" :style="styleIframeHeight"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Api } from "@/api";
import MouseEvents from "@/utils/mouseevents";

export default {
  name: "team",
  components: { },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["gSize", "gLanguage"]),
    frameUrl() {
      return Api.VIRTUAL_URL + Api.TEAM_PANO + (this.gLanguage=="en"?"&" + Api.EN_LANGUAGE_PARAM:"");
    } ,
    styleIframeHeight() {
      return "height: " + (this.gSize.screen.height - this.gSize.header.height) + "px;";
    }
  },
  props: {},
  created() {
    this.$store.dispatch("setTourActive", true);
  },
  mounted() {
    this.$store.dispatch("setTourActive", true);
  },
  destroyed() {
  },
  methods: {   
    contentElementClicked(event) {
      MouseEvents.contentElementClicked(event);
    }
  }
};
</script>
